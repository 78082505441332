import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
//
import ThreeDots from '../assets/img/icons/threeDots.svg';
import { ordCustomSelect } from '../components/Layouts/react-select-custom';
import PopUp from '../components/Popup/customPopUpExtends';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';
//
import { customSwaltAlertAsistencial, loader } from '../helpers';
import { useGetMethod, usePostMethod } from '../Hooks';
//
import tableStyles from '../components/Layouts/tableStyle.module.scss';

export default function DilatationRequirements() {
  const store = useSelector(state => state);

  const [services, setServices] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'CUP', className: 'col-1 text-center' },
      { title: 'Nombre del servicio', className: 'col-4 text-start' },
      { title: 'Tipo', className: 'col-2 text-start' },
      { title: 'Periodo', className: 'col-3 text-start' },
      { title: 'Estado', className: 'col-1 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
  });
  const [dilatationServiceConfig, setDilatationServiceConfig] = useState({
    srvId: null,
    requireDilation: 1,
    alwaysApplies: 0,
    range: null,
    rangeUnitId: null,
    eaccount: store.loginReducer.currentAccount.id,
  });

  const {
    load: dilatationServicesLoader,
    results: dilatationServices,
    trigger: getDilatationServices,
  } = useGetMethod();
  const { load: getIdLoader, trigger: getIdMethod } = useGetMethod();

  const { results: servicesList, trigger: getServicesList } = useGetMethod();

  const { results: times, trigger: getTimes } = useGetMethod();

  const { load: createDilatationServiceLoader, trigger: createDilatationService } = usePostMethod();

  const { load: updateDilatationServiceLoader, trigger: updateDilatationService } = usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getDilatationServices({
        url: '/medical/clinicHistory/configServicesDilation/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id, ...filters },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  }, [
    getDilatationServices,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
    createDilatationServiceLoader,
    updateDilatationServiceLoader,
    filters,
  ]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getIdMethod({
        url: '/medical/generals/service-types/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id },
        token: store.loginReducer.Authorization,
        doAfterSuccess: async res => {
          const filter = res.results.filter(
            x => x.tag === 'consultation' || x.tag === 'diagnostic_aids',
          );
          await getServicesList({
            url: '/medical/offered-service',
            objFilters: {
              eaccount: store.loginReducer.currentAccount.id,
              slim: true,
              typeId: filter.map(x => x.id).join(','),
            },
            token: store.loginReducer.Authorization,
          });
        },
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  }, [
    getIdMethod,
    getServicesList,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
  ]);

  useEffect(() => {
    async function fetchData() {
      await getTimes({
        url: '/medical/time/',
        objFilters: {},
        token: store.loginReducer.Authorization,
      });
    }
    fetchData().catch(error => console.error(error));
  }, [getTimes, store.loginReducer.Authorization]);

  const handleOpenEditModal = item => {
    setServices({
      ...services,
      showModal: true,
      isEditing: true,
    });
    setDilatationServiceConfig({
      ...dilatationServiceConfig,
      id: item.id,
      alwaysApplies: item.alwaysApplies,
      eaccount: item.eaccount,
      range: item.range,
      rangeUnitId: item.rangeUnit.rangeUnitId,
      srvId: item.srvId,
    });
  };

  const handleCloseModal = () => {
    setServices({ ...services, showModal: false, isEditing: false });
    setDilatationServiceConfig({
      srvId: null,
      requireDilation: 1,
      alwaysApplies: 0,
      range: null,
      rangeUnitId: null,
      eaccount: store.loginReducer.currentAccount.id,
    });
  };

  const handleSelectService = selectedOption => {
    setDilatationServiceConfig({
      ...dilatationServiceConfig,
      srvId: selectedOption.value,
    });
  };

  const handleCreateDilatationService = async () => {
    await createDilatationService({
      url: '/medical/clinicHistory/configServicesDilation/',
      method: 'POST',
      token: store.loginReducer.Authorization,
      body: dilatationServiceConfig,
    });
  };

  const handleUpdateDilatationService = async () => {
    await updateDilatationService({
      url: '/medical/clinicHistory/configServicesDilation/',
      method: 'PUT',
      token: store.loginReducer.Authorization,
      body: dilatationServiceConfig,
    });
  };

  const handleSelectTime = option => {
    setDilatationServiceConfig({
      ...dilatationServiceConfig,
      rangeUnitId: option.value,
    });
  };

  const handleRangeChange = event => {
    const rangeValue = event.target.value;
    setDilatationServiceConfig({
      ...dilatationServiceConfig,
      range: rangeValue,
    });
  };

  const handleCheckboxChange = event => {
    const isChecked = event.target.checked;
    setDilatationServiceConfig({
      ...dilatationServiceConfig,
      alwaysApplies: isChecked ? 1 : 0,
      range: isChecked ? null : dilatationServiceConfig.range,
      rangeUnitId: isChecked ? null : dilatationServiceConfig.rangeUnitId,
    });
  };

  const handleSubmit = async () => {
    if (services.isEditing) {
      await handleUpdateDilatationService();
    } else {
      await handleCreateDilatationService();
    }
    handleCloseModal();
  };

  const changeStatus = (isDisabled, id) => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: '¿Desea deshabilitar el estado?',
      confirmButtonText: 'Si, continuar',
    }).then(answer => {
      if (answer.isConfirmed) {
        void updateDilatationService({
          method: 'PUT',
          url: '/medical/clinicHistory/configServicesDilation/',
          token: store.loginReducer.Authorization,
          body: {
            status: isDisabled ? 0 : 1,
            id,
          },
          succesAction: () => {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: `${isDisabled ? 'Deshabilitado' : 'Habilitado'} exitosamente`,
              text: 'Proceso realizado exitosamente.',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
          doAfterException: res => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: res?.message,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const renderRow = dilatationService => {
    return (
      <tr key={dilatationService.id} className={`hover-table-row`}>
        <td>{dilatationService.cupCode}</td>
        <td className='text-start'>{dilatationService.srvName}</td>
        <td className='text-start'>{dilatationService.srvTypeName}</td>
        <td className='text-start'>
          {dilatationService.alwaysApplies === 0
            ? `Cada ${dilatationService.range} ${dilatationService.rangeUnit.rangeUnitName}`
            : 'Siempre'}
        </td>
        <td>
          {dilatationService.status === 1 ? (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_true}>Habilitado</span>
            </div>
          ) : (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_false}>Deshabilitado</span>
            </div>
          )}
        </td>
        <td className='text-end'>
          <PopUp
            showEdit={true}
            showEnable={true}
            position='right'
            triggerSrc={ThreeDots}
            editClickEvent={() => handleOpenEditModal(dilatationService)}
            isEnabled={dilatationService.status === 1}
            enableText={dilatationService.status === 1 ? 'Deshabilitar' : 'Habilitar'}
            enableClickEvent={() => {
              changeStatus(dilatationService.status === 1 ? true : false, dilatationService.id);
            }}
          />
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={services.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: dilatationServices?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getDilatationServices({
              url: '/medical/clinicHistory/configServicesDilation/',
              objFilters: { eaccount: store.loginReducer.currentAccount.id, page: e },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {dilatationServices?.results?.map(dilatationService => renderRow(dilatationService))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    const formattedServicesList = servicesList?.results?.map(service => ({
      label: service.name,
      value: service.id,
    }));
    const currentService = servicesList?.results
      ?.map(service => ({ label: service.name, value: service.id }))
      .find(service => service.value === dilatationServiceConfig.srvId);

    const formattedTimeOptions =
      times?.results?.map(time => ({ label: time.name, value: time.id })) || [];
    const ft = [{ label: 'Seleccionar...', value: null }, ...formattedTimeOptions];

    const currentTime = ft?.find(time => time.value === dilatationServiceConfig.rangeUnitId);

    return (
      <OrdModal
        title={`${services.isEditing ? 'Editar' : 'Nuevo'} requerimiento de dilatación`}
        titleClassName='fs-5'
        show={services.showModal}
        btnYesName='Guardar'
        btnNoName='Cancelar'
        size='md'
        onHide={handleCloseModal}
        btnYesEvent={() => handleSubmit()}
        btnNoEvent={handleCloseModal}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Form.Group>
            <Form.Label className={tableStyles.app_title_input}>
              <div>
                <span className={tableStyles.app_title_new_item}>Servicio</span>
                <span style={{ color: '#FFFF00' }}>*</span>
              </div>
            </Form.Label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              value={currentService}
              onChange={handleSelectService}
              options={formattedServicesList}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
            />
          </Form.Group>
          <h6 className={`${tableStyles.tlnTextPrimary} fw-bold mt-3`}>¿Cada cuánto?</h6>
          <div className='d-flex align-items-center'>
            <Form.Group className='me-3' style={{ width: 100 }}>
              <Form.Label className={tableStyles.app_title_input}>
                <div>
                  <span className={tableStyles.app_title_new_item}>Cantidad</span>
                  <span style={{ color: '#FFFF00' }}>*</span>
                </div>
              </Form.Label>
              <Form.Control
                type='number'
                placeholder='Escribir...'
                className='ord-roundInput'
                value={dilatationServiceConfig.alwaysApplies ? '' : dilatationServiceConfig.range}
                onChange={handleRangeChange}
                disabled={dilatationServiceConfig.alwaysApplies === 1}
                onKeyDown={event => {
                  if (event.key === '-') {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Group>
            <Form.Group className='w-50'>
              <Form.Label className={tableStyles.app_title_input}>
                <div>
                  <span className={tableStyles.app_title_new_item}>Unidad</span>
                  <span style={{ color: '#FFFF00' }}>*</span>
                </div>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                value={currentTime}
                onChange={handleSelectTime}
                options={ft}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                isDisabled={dilatationServiceConfig.alwaysApplies === 1}
              />
            </Form.Group>
          </div>
          <div className='d-flex align-items-center mt-3 mb-4'>
            <input
              type='checkbox'
              name='handleTelemedicine'
              className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
              onChange={handleCheckboxChange}
              checked={dilatationServiceConfig.alwaysApplies ? true : false}
            />
            <div>
              <span className={`${tableStyles.f14} ${tableStyles.tlnTextGray} px-2`}>Siempre</span>
            </div>
          </div>
        </form>
      </OrdModal>
    );
  };

  const render = () => {
    return (
      <>
        {dilatationServicesLoader && loader}
        {getIdLoader && loader}
        {createDilatationServiceLoader && loader}
        {updateDilatationServiceLoader && loader}
        {renderModal()}
        <div className={`${tableStyles.tlnContainer} `}>
          <OrdGenericTemplate
            title='Requerimientos de dilatación'
            titleSize={12}
            buttonNewLabel={'Agregar servicio'}
            buttonNewAction={() => {
              setServices({ ...services, showModal: true });
            }}
            searchWithNew={{
              colSize: '12',
              WSize: '100',
              onChange: e => {
                e.preventDefault();
                setFilters({ ...filters, search: e.target.value });
              },
              buttonNewLabel: 'Agregar servicio',
              buttonNewAction: () => {
                setServices({ ...services, showModal: true });
              },
            }}
          >
            {renderTable()}
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
}
