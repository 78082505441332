import ReactSelect from 'react-select';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../Hooks';

import cardCredit from '../../assets/img/icons/cardCredit.svg';
import close from '../../assets/img/icons/close-blue.svg';
import add from '../../assets/img/icons/grayAdd.svg';
import moneySymbol from '../../assets/img/icons/moneySymbol.svg';
import transfer from '../../assets/img/icons/transfer.svg';

import { generateId, loader } from '../../helpers';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../helpers/numberFormatting';

import NumberFormat from 'react-number-format';

import styles from '../../components/Layouts/tableStyle.module.scss';
import { ordCustomSelect } from '../Layouts/react-select-custom';

const icons = {
  transfer: transfer,
  cash: moneySymbol,
  card: cardCredit,
  debit_note: transfer,
  credit_note: transfer,
  wallet: moneySymbol,
};

const localeSeparators = getLocaleSeparators();

const YES_NO_OPTIONS = [
  { label: 'Seleccionar...', value: null },
  { label: 'Sí', value: 1 },
  { label: 'No', value: 0 },
];

const INITIAL_STATE = {
  depositTypeId: null,
  amount: undefined,
  pendingAmount: undefined,
  type: 'default',
  bankId: null,
  franchiseId: null,
  referenceNumber: undefined,
  donate: null,
  donationValue: undefined,
};

export default function DepositComponent({ 
  payment = 0,
  onChange = () => null,
  walletAmount,
  currency = '',
  cardStyle = {},
  footerStyle = {},
}) {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;

  const [depositList, setDepositList] = useState([{ ...INITIAL_STATE, id: generateId() }]);

  const {
    results: depositTypesList,
    load: depositTypesListLoader,
    trigger: getDepositTypesList,
  } = useGetMethod();

  const { results: bankList, trigger: getBankList } = useGetMethod();

  const { results: franchiseList, trigger: getFranchiseList } = useGetMethod();

  const depositTypes = useMemo(() => {
    if (depositTypesList?.results) {
      const filteredTypes = ['credit_note', 'debit_note'];
      return [
        { label: 'Seleccionar...', value: 'default' },
        ...depositTypesList.results
          .filter(type => !filteredTypes.includes(type.depoTypeLabel))
          .map(type => ({
            ...type,
            label: type.depoTypeName,
            value: type.depoTypeLabel,
          }))
      ];
    }
    return [];
  }, [depositTypesList?.results]);

  const banks = useMemo(() => {
    if (bankList?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...bankList.results.map(bank => ({
          label: bank.description,
          value: bank.id,
        })),
      ];
    }
    return [];
  }, [bankList?.results]);

  const franchises = useMemo(() => {
    if (franchiseList?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...franchiseList.results.map(fra => ({
          label: fra.fraName,
          value: Number(fra.fraId),
        })),
      ];
    }
    return [];
  }, [franchiseList?.results]);

  const totalPayment = useMemo(() => {
    if (depositList.length) {
      return depositList.reduce((acc, el) => {
        return acc + (isNaN(el.amount) ? 0 : el.amount);
      }, 0);
    }
    return 0;
  }, [depositList]);

  useEffect(() => {
    void getDepositTypesList({
      url: '/medical/admissions/depositType/',
      token: token,
      objFilters: { eaccount },
    });
  }, [eaccount, getDepositTypesList, token]);

  const onAddNewDeposit = () => {
    const options = [...depositList, { ...INITIAL_STATE, id: generateId() }];
    setDepositList(options);
    onChange(options);
  };

  const onRemoveDeposit = id => {
    const options = depositList.filter(deposit => deposit.id !== id);
    setDepositList(options);
    onChange(options);
  };

  const onChangeDepositType = option => {
    if (option.type === 'transfer' || option.type === 'card') {
      void getBankList({
        url: '/admin/bankingEntities',
        token: token,
        objFilters: { eaccount },
      });
    }

    if (option.type === 'card') {
      void getFranchiseList({
        url: '/accounting/franchise/',
        token: token,
        objFilters: { eaccount },
      });
    }

    const options = depositList.map(dep => {
      if (dep.id === option.deposit.id) {
        return { id: dep.id, ...INITIAL_STATE, type: option.type, depositTypeId: option.id };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeBank = (bankId, depositId) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, bankId };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeFranchise = (franchiseId, depositId) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, franchiseId };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeAmount = (value, deposit) => {
    const options = depositList.map(dep => {
      if (dep.id === deposit.id) {
        return {
          ...dep,
          amount: value,
          pendingAmount:
            deposit.type === 'cash' && value > payment
              ? value - payment - (deposit?.donationValue ?? 0)
              : undefined,
          returnedChange: null,
          donate: null,
          donationValue: undefined,
        };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeDonation = (value, depositId) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, donate: value, donationValue: undefined };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeReturnedChange = (value, deposit) => {
    const options = depositList.map(dep => {
      if (dep.id === deposit.id) {
        return {
          ...dep,
          returnedChange: value,
        };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeDonationValue = (value, deposit) => {
    const options = depositList.map(dep => {
      if (dep.id === deposit.id) {
        return {
          ...dep,
          donationValue: value,
          pendingAmount: deposit?.amount - payment - (value ?? 0),
        };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeRefNum = (value, depositId) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, referenceNumber: value };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const renderDefaultType = () => {
    return (
      <>
        <div className='col-lg-6'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>&nbsp;</small>
          <input className='ord-roundInput w-100' disabled={true} />
        </div>
        <div className='col-lg-6 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>&nbsp;</small>
          <input className='ord-roundInput w-100' disabled={true} />
        </div>
        <div className='col-lg-6 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>&nbsp;</small>
          <input className='ord-roundInput w-100' disabled={true} />
        </div>
      </>
    );
  };

  const renderWalletType = deposit => {
    return (
      <div className='col-lg-6'>
        <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
          Monto pagado
          <span className='text-danger'>*</span>
        </small>
        <NumberFormat
          className='ord-roundInput w-100'
          allowNegative={false}
          placeholder='Escribe...'
          decimalScale={0}
          prefix='$'
          thousandSeparator={localeSeparators.groupSeparator}
          decimalSeparator={localeSeparators.decimalSeparator}
          allowLeadingZeros={false}
          value={deposit?.amount}
          onValueChange={({ floatValue }) => {
            const value = floatValue;
            void onChangeAmount(value, deposit);
          }}
          isAllowed={({ floatValue }) => {
            const value = floatValue ?? 0;
            return walletAmount > payment ? value <= payment : value <= walletAmount;
          }}
        />
      </div>
    );
  };

  const renderTransferType = deposit => {
    return (
      <>
        <div className='col-lg-6'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            Monto pagado
            <span className='text-danger'>*</span>
          </small>
          <NumberFormat
            className='ord-roundInput w-100'
            allowNegative={false}
            placeholder='Escribe...'
            decimalScale={0}
            prefix='$'
            thousandSeparator={localeSeparators.groupSeparator}
            decimalSeparator={localeSeparators.decimalSeparator}
            allowLeadingZeros={false}
            value={deposit?.amount}
            onValueChange={({ floatValue }) => {
              const value = floatValue;
              void onChangeAmount(value, deposit);
            }}
            isAllowed={({ floatValue }) => {
              const value = floatValue ?? 0;
              return value <= payment;
            }}
          />
        </div>
        <div className='col-lg-6 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            Banco
            <span className='text-danger'>*</span>
          </small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            options={banks}
            styles={ordCustomSelect}
            value={banks.find(bank => bank.value === deposit?.bankId)}
            onChange={option => {
              onChangeBank(option.value, deposit.id);
            }}
          />
        </div>
        <div className='col-lg-6 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            No. referencia
            <span className='text-danger'>*</span>
          </small>
          <NumberFormat
            className='ord-roundInput w-100'
            allowNegative={false}
            placeholder='Escribe...'
            decimalScale={0}
            allowLeadingZeros
            value={deposit?.referenceNumber}
            onValueChange={({ value }) => {
              void onChangeRefNum(value, deposit.id);
            }}
          />
        </div>
      </>
    );
  };

  const renderCashType = deposit => {
    return (
      <>
        <div className='col-lg-6'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            Monto pagado
            <span className='text-danger'>*</span>
          </small>
          <NumberFormat
            className='ord-roundInput w-100'
            allowNegative={false}
            placeholder='Escribe...'
            decimalScale={0}
            prefix='$'
            thousandSeparator={localeSeparators.groupSeparator}
            decimalSeparator={localeSeparators.decimalSeparator}
            allowLeadingZeros={false}
            value={deposit?.amount}
            onValueChange={({ floatValue }) => {
              const value = floatValue;
              void onChangeAmount(value, deposit);
            }}
          />
        </div>
        <div className='col pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>Vuelto total</small>
          <NumberFormat
            disabled
            className='ord-roundInput w-100'
            allowNegative={false}
            placeholder='Escribe...'
            decimalScale={0}
            prefix='$'
            thousandSeparator={localeSeparators.groupSeparator}
            decimalSeparator={localeSeparators.decimalSeparator}
            allowLeadingZeros={false}
            value={deposit?.amount > payment ? deposit?.pendingAmount : 0}
          />
        </div>
        {deposit?.amount > payment && (
          <>
            <div className='col-lg-3 pt-2'>
              <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>¿Donar vuelto?</small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                value={YES_NO_OPTIONS.find(opt => opt.value === deposit?.donate)}
                options={YES_NO_OPTIONS}
                styles={ordCustomSelect}
                onChange={option => {
                  onChangeDonation(option.value, deposit.id);
                }}
              />
            </div>
            {!!deposit?.donate && (
              <div className='col-lg-3 pt-2'>
                <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>¿Cuánto?</small>
                <NumberFormat
                  className='ord-roundInput w-100'
                  allowNegative={false}
                  placeholder='Escribe...'
                  decimalScale={0}
                  prefix='$'
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  allowLeadingZeros={false}
                  value={deposit?.donationValue}
                  onValueChange={({ floatValue }) => {
                    void onChangeDonationValue(floatValue, deposit);
                  }}
                  isAllowed={({ floatValue }) => {
                    const value = floatValue ?? 0;
                    return value <= deposit?.amount - payment - (deposit?.donationValue ?? 0);
                  }}
                />
              </div>
            )}
            <div className='col-lg-3 pt-2'>
              <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>¿Vuelto entregado?</small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                options={YES_NO_OPTIONS}
                styles={ordCustomSelect}
                value={YES_NO_OPTIONS.find(opt => opt.value === deposit?.returnedChange)}
                onChange={option => {
                  onChangeReturnedChange(option.value, deposit);
                }}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const renderCardType = deposit => {
    return (
      <>
        <div className='col-lg-6'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            Monto pagado
            <span className='text-danger'>*</span>
          </small>
          <NumberFormat
            className='ord-roundInput w-100'
            allowNegative={false}
            placeholder='Escribe...'
            decimalScale={0}
            prefix='$'
            thousandSeparator={localeSeparators.groupSeparator}
            decimalSeparator={localeSeparators.decimalSeparator}
            allowLeadingZeros={false}
            value={deposit?.amount}
            onValueChange={({ floatValue }) => {
              const value = floatValue;
              void onChangeAmount(value, deposit);
            }}
            isAllowed={({ floatValue }) => {
              const value = floatValue ?? 0;
              return value <= payment;
            }}
          />
        </div>
        <div className='col-lg-4 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            Banco
            <span className='text-danger'>*</span>
          </small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            options={banks}
            styles={ordCustomSelect}
            value={banks.find(bank => bank.value === deposit?.bankId)}
            onChange={option => {
              onChangeBank(option.value, deposit.id);
            }}
          />
        </div>
        <div className='col-lg-4 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            Franquicia
            <span className='text-danger'>*</span>
          </small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            options={franchises}
            styles={ordCustomSelect}
            value={franchises.find(fra => fra.value === deposit?.franchiseId)}
            onChange={option => {
              onChangeFranchise(option.value, deposit.id);
            }}
          />
        </div>
        <div className='col-lg-4 pt-2'>
          <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
            No. referencia
            <span className='text-danger'>*</span>
          </small>
          <NumberFormat
            className='ord-roundInput w-100'
            allowNegative={false}
            placeholder='Escribe...'
            decimalScale={0}
            allowLeadingZeros
            value={deposit?.referenceNumber}
            onValueChange={({ value }) => {
              void onChangeRefNum(value, deposit.id);
            }}
          />
        </div>
      </>
    );
  };

  const renderNoteType = deposit => {
    return (
      <div className='col-lg-6'>
        <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
          Monto pagado
          <span className='text-danger'>*</span>
        </small>
        <NumberFormat
          className='ord-roundInput w-100'
          allowNegative={false}
          placeholder='Escribe...'
          decimalScale={0}
          prefix='$'
          thousandSeparator={localeSeparators.groupSeparator}
          decimalSeparator={localeSeparators.decimalSeparator}
          allowLeadingZeros={false}
          value={deposit?.amount}
          onValueChange={({ floatValue }) => {
            const value = floatValue;
            void onChangeAmount(value, deposit);
          }}
          isAllowed={({ floatValue }) => {
            const value = floatValue ?? 0;
            return value <= payment;
          }}
        />
      </div>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {depositTypesListLoader && loader}

        {depositList.map(deposit => (
          <div
            key={deposit.id}
            className={`${styles.addCardWhite} my-3 py-3 px-3 position-relative w-100`}
            style={{ ...cardStyle }}
          >
            {depositList.length > 1 && (
              <div
                className='position-absolute'
                style={{ right: 10, top: 5 }}
                onClick={() => onRemoveDeposit(deposit.id)}
              >
                <img src={close} className='pointer' alt='cerrar' width={12} />
              </div>
            )}
            <div className='d-flex p-0 w-100'>
              <div style={{ minWidth: 100 }}>
                <div
                  className={`${styles.addCardWhite} h-100 p-3 d-flex align-items-center justify-content-center`}
                >
                  <img src={icons[deposit.type]} alt='' width='50%' height='auto' />
                </div>
              </div>
              <div className='w-100 ms-3 row gx-2 align-items-end'>
                <div className='col-lg-6'>
                  <small className={`${styles.ordDarkBlueText} ms-1 d-block`}>
                    Tipo de recaudo
                    <span className='text-danger'>*</span>
                  </small>
                  <ReactSelect
                    noOptionsMessage={() => 'No hay datos'}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    options={depositTypes}
                    styles={ordCustomSelect}
                    value={depositTypesList?.results
                      ?.map(dep => ({ label: dep.depoTypeName, value: dep.depoTypeLabel }))
                      ?.find(type => type.value === deposit.type)}
                    onChange={option => {
                      onChangeDepositType({
                        id: option.depoTypeId,
                        type: option.value,
                        deposit,
                      });
                    }}
                  />
                </div>
                {deposit.type === 'default' && renderDefaultType()}
                {deposit.type === 'transfer' && renderTransferType(deposit)}
                {deposit.type === 'cash' && renderCashType(deposit)}
                {deposit.type === 'card' && renderCardType(deposit)}
                {deposit.type === 'debit_note' && renderNoteType(deposit)}
                {deposit.type === 'credit_note' && renderNoteType(deposit)}
                {deposit.type === 'wallet' && renderWalletType(deposit)}
              </div>
            </div>
          </div>
        ))}
        <div className='d-flex align-items-center' style={{ ...footerStyle }}>
          {depositList.every(dep => !!dep?.amount) && payment - totalPayment > 0 && (
            <button className={`${styles.appButtonHover} me-4`} onClick={onAddNewDeposit}>
              <b
                className={`${styles.tlnTextGray} ${styles.f14} ${styles.appTextHoverTurquoise} me-2`}
              >
                Agregar otro recaudo
              </b>
              <img
                alt='addIcon'
                src={add}
                height='17px'
                className={`${styles.AppIconHoverTurquoise}`}
              />
            </button>
          )}

          <div
            className={`fw-bold gap-3 d-flex ${Number(totalPayment) < payment ? styles.orangeLightText : styles.darkerBlueText}`}
          >
            <span>Faltante</span>
            <span>
              {formatNumberWithoutDecimals(
                Number(totalPayment) < payment ? payment - totalPayment : 0,
                currency,
              )}
            </span>
          </div>
        </div>
      </>
    );
  };

  return renderComponent();
}
