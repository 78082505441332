import ReactSelect from 'react-select';

import { Fragment, useEffect, useMemo, useState } from 'react';
import { useGetMethod, usePostMethod } from '../../Hooks';

import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import { useSelector } from 'react-redux';
import { customSwaltAlertAsistencial, loader } from '../../helpers';

const DEFAULT_OPTION = { label: 'Seleccionar...', value: null };

const PAYLOAD_INITIAL_STATE = {
  name: '',
  clinicalHistoryFields: DEFAULT_OPTION,
  condition: DEFAULT_OPTION,
  value: '',
};

export default function AddVariable({
  open = false,
  editPayload = null,
  onClose = () => null,
  onSuccess = () => null,
}) {
  const token = useSelector(store => store.loginReducer.Authorization);
  const accountId = useSelector(store => store.loginReducer.currentAccount.id);

  const [payload, setPayload] = useState(PAYLOAD_INITIAL_STATE);

  const {
    trigger: getConditions,
    load: conditionsLoader,
    results: conditionsResult,
  } = useGetMethod();

  const {
    trigger: getClinicalHistoryFields,
    load: clinicalHistoryLoader,
    results: clinicalHistoryResults,
  } = useGetMethod();

  const { trigger: createVariable, load: createVariableLoader } = usePostMethod();

  const formattedConditions = useMemo(() => {
    const options = [DEFAULT_OPTION];
    if (conditionsResult?.results) {
      for (const condition of conditionsResult.results) {
        const option = {
          value: condition.id,
          label: `${condition.name} (${condition.value})`,
        };
        options.push(option);
      }
    }
    return options;
  }, [conditionsResult?.results]);

  const formattedClinicalHistory = useMemo(() => {
    const options = [DEFAULT_OPTION];
    if (clinicalHistoryResults?.results) {
      for (const ch of clinicalHistoryResults.results) {
        const option = { value: ch.id, label: ch.label };
        options.push(option);
      }
    }
    return options;
  }, [clinicalHistoryResults?.results]);

  useEffect(() => {
    if (open) {
      void getConditions({
        url: '/medical/operator',
        objFilters: {
          page: 1,
          search: '',
          perpage: 10,
          idAccount: accountId,
        },
        token: token,
      });

      void getClinicalHistoryFields({
        url: '/medical/clinical-history-fields',
        objFilters: {},
        token: token,
      });
    }
  }, [open, accountId, getConditions, token, getClinicalHistoryFields]);

  useEffect(() => {
    if (editPayload) {
      const conditionOption = formattedConditions.find(
        cond => cond.value === editPayload.operatorId,
      );

      setPayload({
        name: editPayload.name,
        clinicalHistoryFields: {
          label: editPayload.fieldName,
          value: editPayload.field,
        },
        condition: conditionOption ?? DEFAULT_OPTION,
        value: editPayload.value,
      });
    }
  }, [editPayload, formattedConditions]);

  const onSubmitForm = () => {
    if (
      !payload.name ||
      !payload.clinicalHistoryFields.value ||
      !payload.condition.value ||
      !payload.value
    ) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe rellenar todos los campos obligatorios',
        showCancelButton: false,
      });
      return;
    }
    createVariable({
      token: token,
      body: {
        name: payload.name,
        field: payload.clinicalHistoryFields.value,
        operatorId: payload.condition.value,
        value: payload.value,
        idAccount: accountId,
      },
      method: editPayload ? 'PUT' : 'POST',
      url: `/medical/protocolConditions${editPayload?.id ? `/${editPayload.id}` : ''}`,
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: `Variable ${editPayload ? 'actualizada' : 'creada'} exitosamente`,
          text: `Se ha ${editPayload ? 'actualizado' : 'creado'} la variable: ${payload.name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        onSuccess();
        onCloseModal();
      },
    });
  };

  const onCloseModal = () => {
    setPayload(PAYLOAD_INITIAL_STATE);
    onClose();
  };

  const renderComponent = () => {
    return (
      <Fragment>
        {(conditionsLoader || clinicalHistoryLoader || createVariableLoader) && loader}
        <OrdModal
          title='Agregar variable'
          show={open}
          onHide={() => onCloseModal()}
          btnYesName='Aceptar'
          btnNoName='Cancelar'
          size={750}
          btnNoEvent={() => onCloseModal()}
          btnYesEvent={() => onSubmitForm()}
        >
          <div className='mb-3'>
            <label className={`${tableStyles.ordDarkBlueText}`}>
              Nombre<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              className='ord-roundInput w-100'
              placeholder='Escribe...'
              value={payload.name}
              onChange={({ target }) => {
                setPayload(state => ({ ...state, name: target.value }));
              }}
            />
          </div>

          <div className='mb-3'>
            <label className={`${tableStyles.ordDarkBlueText}`}>
              Campo de historia clínica<span className='text-danger'>*</span>
            </label>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={formattedClinicalHistory}
              className='text-secondary '
              placeholder='Seleccionar...'
              styles={ordCustomSelect}
              value={payload.clinicalHistoryFields}
              onChange={option => {
                setPayload(state => ({ ...state, clinicalHistoryFields: option }));
              }}
            />
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <label className={`${tableStyles.ordDarkBlueText}`}>
                Condición<span className='text-danger'>*</span>
              </label>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={formattedConditions}
                className='text-secondary '
                placeholder='Seleccionar...'
                styles={ordCustomSelect}
                value={payload.condition}
                onChange={option => {
                  setPayload(state => ({ ...state, condition: option }));
                }}
              />
            </div>
            <div className='col'>
              <label className={`${tableStyles.ordDarkBlueText}`}>
                Valor<span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className='ord-roundInput w-100'
                placeholder='Escribe...'
                value={payload.value}
                onChange={({ target }) => {
                  setPayload(state => ({ ...state, value: target.value }));
                }}
              />
            </div>
          </div>
        </OrdModal>
      </Fragment>
    );
  };

  return renderComponent();
}
