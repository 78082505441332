import { useState } from 'react';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Nav from 'react-bootstrap/Nav';
import '../OrderingModule/orderingStyles.css';
import { useHistory } from 'react-router-dom';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Variables } from './ServiceParamsTabs/Variables';
import { Conditions } from './ServiceParamsTabs/Conditions';
import { useGetMethod, useMultiGetMethod } from '../Hooks/useFetch';
import { loader } from '../helpers/helpers';

export const ServicesParam = () => {
  const [profile] = useState(2);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);

  const {
    results: initialListCondition,
    load: initialListConditionLoader,
    trigger: getInitialListCondition,
  } = useMultiGetMethod();

  const {
    results: initialListVariables,
    load: initialListVariablesLoader,
    trigger: getInitialListVariables,
  } = useGetMethod();

  return (
    <>
      {(initialListConditionLoader || initialListVariablesLoader) && loader}
      <OrdGenericTemplate
        title={'Parametrización de servicios'}
        showBackArrow
        titleSize={12}
        backArrowAction={() => history.push('/asistencial/tmHistoriaClinica/serviciosMedicos')}
      >
        <div className='simpleTabs__Container m-0 ml-3'>
          <TabContainer defaultActiveKey={profile}>
            <Nav className='flex-row simpleTabs'>
              <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={2}>
                <Nav.Link
                  onClick={() => setActiveTab(2)}
                  className={`${activeTab === 2 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
                  eventKey={2}
                >
                  Variables
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={3}>
                <Nav.Link
                  onClick={() => setActiveTab(3)}
                  className={`${activeTab === 3 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
                  eventKey={3}
                >
                  Condiciones
                </Nav.Link>
              </Nav.Item>
              <div style={{ flex: 1 }} className={`simpleTabs__filler`}></div>
            </Nav>
            <TabContent className='simpleTabs__relative' style={{ overflowY: 'unset' }}>
              <TabPane key={'panelTab_2'} eventKey={2} className='simpleTabs__Area'>
                <Variables
                  initialList={initialListVariables}
                  getInitialList={getInitialListVariables}
                />
              </TabPane>
              <TabPane key={'panelTab_3'} eventKey={3} className='simpleTabs__Area'>
                <Conditions
                  initialList={initialListCondition}
                  getInitialList={getInitialListCondition}
                />
              </TabPane>
            </TabContent>
          </TabContainer>
        </div>
      </OrdGenericTemplate>
    </>
  );
};
