import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../Hooks';

import Agregar from '../../assets/img/icons/add-check.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg';

import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { customSwaltAlert, loader } from '../../helpers';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import CustomPopupExtend from '../Popup/customPopUpExtends';

import paginationStyles from '../../components/Layouts/pagination.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import SelectComponent from '../SelectComponent/SelectComponent';

export default function TableAccountingPeriods() {
  const storage = useSelector(state => state);
  const eaccount = storage?.loginReducer?.currentAccount?.id;
  const token = storage?.loginReducer?.Authorization;

  const {
    trigger: getAccountingPeriod,
    load: accountingPeriodLoader,
    results: accountingPeriod,
  } = useGetMethod();
  const { trigger: getMonths, load: monthsLoader, results: months } = useGetMethod();
  const { trigger: updateStatus, load: updateStatusLoader } = usePostMethod();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount,
    month: undefined,
  });

  const [period, setPeriod] = useState({
    show: false,
    data: {},
    isEditing: false,
    isCreating: false,
  });

  const [trigger, setTrigger] = useState(1);

  const optionYear = [{ label: 'Seleccionar...', value: '' }];
  for (let step = 2020; step < 2050; step++) {
    optionYear.push({ value: step, label: step });
  }
  const monthsArray =
    months?.results?.map(month => ({
      value: month.id,
      label: month.name,
    })) || [];

  const fetchData = async () => {
    try {
      const result = await getAccountingPeriod({
        url: '/accounting/cutoffDates/',
        objFilters: {
          ...filters,
        },
        token: token,
      });
      if (!result?.success) {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          message: result.message,
          confirmButtonText: 'Aceptar',
        });
      }
    } catch (error) {
      console.error('error fetching data', error);
    }
  };

  useEffect(() => {
    const getMonthsFunction = async () => {
      try {
        const result = await getMonths({
          url: '/payroll/months',
          objFilters: {},
          token: token,
        });
        if (!result?.success) {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            message: result.message,
            confirmButtonText: 'Aceptar',
          });
        }
      } catch (error) {
        console.error('error fetching data', error);
      }
    };

    getMonthsFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const changeStatus = (cutoff_id, month, year, statusItem, month_tag, status) => {
    const statusString = statusItem === '1' ? 'deshabilitará' : 'habilitará';

    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se ${statusString} el periodo contable ${month_tag} de ${year}`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        updateStatus({
          token: token,
          body: {
            cutoff_id,
            month,
            year,
            eaccount,
            status: status === '1' ? 0 : 1,
          },
          method: 'PUT',
          url: '/accounting/cutoffDates/',
          succesAction: result => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: 'Actualizado exitosamente',
              text: result.message,
              confirmButtonText: 'Aceptar',
            }).then(async response => {
              if (response.isConfirmed) {
                await fetchData();
              }
            });
          },
        });
      }
    });
  };

  const handleSubmit = (data = null) => {
    if (!period?.data?.month || !period?.data?.year?.trim()) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intente de nuevo',
        text: 'Todos los campos son obligatorios',
        showCancelButton: false,
      });
    }

    let body;
    if (period?.isCreating) {
      body = {
        eaccount,
        month: period?.data?.month,
        year: period?.data?.year,
      };
    } else {
      if (period?.isEditing) {
        body = {
          eaccount,
          cutoff_id: period?.data?.id,
          month: period?.data?.month,
          year: period?.data?.year,
        };
      }
    }

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${period.isEditing || data ? 'actualizará' : 'creará'} el periodo contable`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        updateStatus({
          token: token,
          body: body,
          method: period?.isEditing ? 'PUT' : 'POST',
          url: '/accounting/cutoffDates/',
          doAfterSuccess: () => {
            fetchData();
            setPeriod({
              data: {},
              show: false,
              isCreating: false,
              isDetail: false,
              isEditing: false,
            });
          },
          succesAction: result =>
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: `${period.isEditing ? 'Actualizado' : 'Creado'} exitosamente`,
              text: result.message,
              confirmButtonText: 'Aceptar',
            }),
        });
      }
    });
  };

  const renderFilters = () => {
    return (
      <>
        <Row className='d-flex justify-content-end'>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Mes</p>
            <SelectComponent
              styles={customSelectNewDark}
              placeholder={'Seleccionar...'}
              key={'Mes' + trigger}
              value={monthsArray?.filter(x => x.value === filters?.month)}
              onChange={e => {
                setFilters({
                  ...filters,
                  month: e.value,
                  page: 1,
                });
                setTrigger(trigger + 1);
              }}
              options={[{ value: null, label: 'Seleccionar...' }, ...monthsArray]}
            ></SelectComponent>
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Año</p>
            <SelectComponent
              styles={customSelectNewDark}
              placeholder={'Seleccionar...'}
              key={'Año' + trigger}
              value={optionYear.filter(x => x.value === filters?.year)}
              onChange={e => {
                setFilters({
                  ...filters,
                  year: e.value,
                  page: 1,
                });
                setTrigger(trigger + 1);
              }}
              options={optionYear}
            ></SelectComponent>
          </Col>
          <Col xs={2} className='d-flex align-self-end gap-1'></Col>
          <Col xs={4} className='align-self-end'>
            <div className='d-flex justify-content-end'>
              <div
                onClick={() =>
                  setPeriod({
                    ...period,
                    show: true,
                    isCreating: true,
                  })
                }
                className={tableStyles.createNomModule}
              >
                <b className={`mr-2`}>Crear periodo contable</b>
                <img src={Agregar} alt='new' />
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const renderTable = () => {
    const headers = [
      <th key={1} style={{ whiteSpace: 'nowrap' }} className='text-start px-4 col-3'>
        Mes
      </th>,
      <th key={2} style={{ whiteSpace: 'nowrap' }} className='text-start px-4'>
        Año
      </th>,
      <th key={3} className='text-center w-20 px-2 ps-3'>
        Estado
      </th>,
      <th key={4} style={{ whiteSpace: 'nowrap' }} className='text-center px-2'></th>,
    ];

    const renderList = () => {
      let table = [];

      if (Array.isArray(accountingPeriod?.results)) {
        table = accountingPeriod?.results?.map((x, index) => {
          return (
            <tr key={'renderList' + index} className='hover-table-row'>
              <td style={{ whiteSpace: 'nowrap' }} className='text-start px-4  col-3'>
                {x?.month_tag || '-'}
              </td>
              <td style={{ whiteSpace: 'nowrap' }} className='text-start px-4 '>
                {x?.year || '-'}
              </td>
              <td className='text-center w-20 col-1 ps-3'>
                <div
                  className='rounded-pill p-1'
                  style={{
                    backgroundColor: `${x.background}`,
                  }}
                >
                  <b style={{ color: `${x.fontcolor}` }}>{x.status_label}</b>
                </div>
              </td>
              <td className='text-center col-1 w-15'>
                <CustomPopupExtend
                  showEdit
                  showEnable
                  isEnabled={x.status === '1'}
                  enableText={x.status === '1' ? 'Deshabilitar' : 'Habilitar'}
                  editText='Editar'
                  enableClickEvent={() =>
                    changeStatus(x.id, x.month, x.year, x.status, x.month_tag, x.status)
                  }
                  editClickEvent={() =>
                    setPeriod({
                      ...period,
                      show: true,
                      isEditing: true,
                      isCreating: false,
                      data: x,
                    })
                  }
                  triggerSrc={ThreeDots}
                  alt='iconSearch'
                  noHover
                />
              </td>
            </tr>
          );
        });
      }
      return table;
    };

    return (
      <GenericTableNew headers={headers} dark={true} shadow>
        {renderList()}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {(accountingPeriodLoader || updateStatusLoader || monthsLoader) && loader}
        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}> Periodos Contables</h1>

            {renderFilters()}
            {renderTable()}

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                pag {filters.page} {'de'}{' '}
                {Math.ceil(accountingPeriod?.row_count / filters.perpage)
                  ? Math.ceil(accountingPeriod?.row_count / filters.perpage)
                  : '1'}{' '}
                {''} ({accountingPeriod?.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={accountingPeriod?.row_count}
                pageRangeDisplayed={5}
                onChange={page => setFilters({ ...filters, page: page })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>

            <ModalNew
              show={period.show}
              onHide={() =>
                setPeriod({
                  ...period,
                  show: false,
                  isCreating: false,
                  data: {},
                  isEditing: false,
                })
              }
              title={
                period?.isEditing
                  ? 'Editar periodo contable'
                  : period.isCreating
                    ? 'Nuevo periodo contable'
                    : ''
              }
              btnYesName={'Aceptar'}
              btnYesEvent={period.isEditing || period.isCreating ? () => handleSubmit() : null}
              size={'500'}
            >
              <Row>
                <Col xs={12}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    <b>Mes</b>
                  </p>
                  <SelectComponent
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    key={'Mes' + trigger}
                    onChange={e => {
                      setPeriod({
                        ...period,
                        data: { ...period.data, month: e.value },
                      });
                    }}
                    value={monthsArray.filter(x => String(x.value) === String(period?.data?.month))}
                    options={[{ value: null, label: 'Seleccionar...' }, ...monthsArray]}
                  ></SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    <b>Año</b>
                  </p>
                  <input
                    type='number'
                    placeholder='Ingresar año'
                    value={period?.data?.year}
                    onChange={e =>
                      setPeriod({
                        ...period,
                        data: { ...period.data, year: e.target.value },
                      })
                    }
                    className='register-inputs text-secondary'
                    name='position'
                  />
                </Col>
              </Row>
            </ModalNew>
          </div>
        </div>
      </>
    );
  };
  return render();
}
