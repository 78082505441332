import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import alert from '../../../assets/img/icons/alert-circle-auxiliary-red.svg';
import { isEmptyOrUndefined, loader } from '../../../helpers/helpers';

import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Schedule } from './schedule';

import styles from '../../../components/Layouts/tableStyle.module.scss';

const Programming = ({
  token,
  order,
  options,
  setOptions,
  procedure,
  detailPatient,
  detailLoaderPatients,
  ListPacientLoader,
  postTicketFunction,
  setTriggerTicket,
  triggerTicket,
  setTicketValue,
}) => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();

  const [selectProgramming, setSelectProgramming] = useState({
    serviceId: order.serviceId,
    insurer: {},
  });
  const [continueInsurerRequired, setContinueInsurerRequired] = useState(false);

  const getInsurer = useCallback(
    insurer => {
      setSelectProgramming(state => ({
        ...state,
        insurer: insurer,
      }));
      setOptions(state => ({
        ...state,
        showProgramming: true,
        showSchedule: true,
      }));
    },
    [setOptions],
  );

  useEffect(() => {
    if (procedure?.contracts) getInsurer(procedure?.contracts);
    setContinueInsurerRequired(false);
  }, [procedure?.contracts, getInsurer]);

  const goBackFunction = () => {
    if (options.showSchedule) {
      setOptions({ ...options, showSchedule: false });
    } else if (!options.showSchedule) {
      setOptions({ ...options, showCards: true, showProgramming: false });
    }

    dispatch({
      type: 'CHECKED_FILTER_CHECK_TELE',
      payload: false,
    });
  };

  useEffect(() => {
    if (!options.showSchedule) {
      dispatch({
        type: 'ACTIVE_FILTER_CHECK_TELE',
        payload: false,
      });
    }
  }, [options.showSchedule, dispatch]);

  const renderTooltipAlert = () => (
    <Tooltip className={`${styles.tooltipAlert} tooltipAlert`}>
      Creada manualmente, falla del Webcraping.{' '}
    </Tooltip>
  );

  return (
    <>
      {(detailLoaderPatients || ListPacientLoader) && loader}

      <div
        style={{
          width: '100%',
          backgroundColor: '#F6FEFF',
          overflow: 'auto',
          height: 'inherit',
        }}
      >
        <div className='container'>
          <OrdGenericTemplate
            showBackArrow={true}
            backArrowAction={() => goBackFunction()}
            titleStyle={{}}
            titleSize='8'
            filterSwitch={{
              active: options.showSchedule,
              size: 4,
              checked: store.filterCheckTeleScheduleReducer.checked,
              label: 'Telemedicina',
              onChange: () => {
                dispatch({
                  type: 'CHECKED_FILTER_CHECK_TELE',
                  payload: !store.filterCheckTeleScheduleReducer.checked,
                });
              },
            }}
            title={'Programación'}
          >
            {options.showSchedule || continueInsurerRequired ? (
              <>
                <Card
                  className={`card ${styles.app_card_custom} my-3 p-3`}
                  style={{ cursor: 'default' }}
                >
                  <div className='row g-3'>
                    {!isEmptyOrUndefined(selectProgramming.insurer?.companyTypeName) && (
                      <div className='col-lg-2'>
                        <div className={styles.app_title_card_programming}>Tipo de afiliado</div>
                        <div className={styles.ordDarkBlueText}>
                          {selectProgramming.insurer.companyTypeName
                            ? selectProgramming.insurer.companyTypeName
                            : '-'}
                        </div>
                      </div>
                    )}

                    {!isEmptyOrUndefined(selectProgramming.insurer?.corporateClient) && (
                      <div className='col-lg-2'>
                        <div className={styles.app_title_card_programming}>Entidad</div>
                        <div className={styles.ordDarkBlueText}>
                          {selectProgramming.insurer.corporateClient}
                        </div>
                      </div>
                    )}
                    {!isEmptyOrUndefined(selectProgramming.insurer?.contractName) && (
                      <div className='col-lg-3'>
                        <div className={styles.app_title_card_programming}>Contrato</div>
                        <div className={styles.ordDarkBlueText}>
                          {selectProgramming.insurer?.contractName}
                        </div>
                      </div>
                    )}
                    {!isEmptyOrUndefined(selectProgramming.insurer?.population) && (
                      <div className='col-lg-3'>
                        <div className={styles.app_title_card_programming}>Población</div>
                        <div className={styles.ordDarkBlueText}>
                          {selectProgramming.insurer?.population}
                        </div>
                      </div>
                    )}
                    {!isEmptyOrUndefined(selectProgramming.insurer?.crtName) && (
                      <div className='col-lg-2'>
                        <div className={styles.app_title_card_programming}>Grupo de ingreso</div>
                        <div className={styles.ordDarkBlueText}>
                          {selectProgramming.insurer?.crtName}
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
                <Schedule
                  token={token}
                  contractId={selectProgramming?.insurer?.contractId}
                  corporateClientId={selectProgramming?.insurer?.corporateClientId}
                  populationId={selectProgramming?.insurer?.populationId}
                  procedure={procedure}
                  detailPatient={detailPatient}
                  companyType={selectProgramming?.insurer?.companyTypeId}
                  popId={selectProgramming?.insurer?.populationId}
                  wsValidated={selectProgramming?.insurer?.wsValidated}
                  crtName={selectProgramming?.insurer?.crtName}
                  crtId={selectProgramming?.insurer?.crtId}
                  attGroupId={selectProgramming?.insurer?.attGroupId}
                  postTicketFunction={postTicketFunction}
                  setTriggerTicket={setTriggerTicket}
                  triggerTicket={triggerTicket}
                  setTicketValue={setTicketValue}
                  insureSelected={selectProgramming?.insurer}
                  goBackFunction={goBackFunction}
                  order={order}
                />
              </>
            ) : (
              <>
                {detailPatient?.results?.contracts.map((item, index) => {
                  if (item.isMain === 0 && item.isEnabled === 1) {
                    return (
                      <Card
                        className={styles.app_card_custom_programming}
                        key={index}
                        onClick={() => {
                          postTicketFunction('insurance');
                          getInsurer(item);
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <span className={styles.app_title_order}>Aseguradora Voluntaria</span>
                          {item.wsValidated === 1 && (
                            <OverlayTrigger
                              placement='top'
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipAlert()}
                              style={{ backgroundColor: '#F39682' }}
                            >
                              <img alt='alert' src={alert} />
                            </OverlayTrigger>
                          )}
                        </div>
                        {item.companyTypeTag === 'special_agreement' ? (
                          <>
                            <div key={index} className={styles.app_container_card_ord}>
                              <div className={`${styles.app_container_info_card} w-100`}>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Tipo
                                  </span>
                                  <span className={`col-6 ${styles.app_subtitle_card_order}`}>
                                    {item.companyTypeName ? item.companyTypeName : '-'}
                                  </span>
                                </div>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Empresa
                                  </span>
                                  <span className={`col-6 ${styles.app_subtitle_card_order}`}>
                                    {item.corporateClient ? item.corporateClient : '-'}
                                  </span>
                                </div>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Contrato
                                  </span>
                                  <span className={`col-12 ${styles.app_subtitle_card_order}`}>
                                    {item.contractName ? item.contractName : '-'}
                                  </span>
                                </div>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Población
                                  </span>
                                  <span className={`col-9 ${styles.app_subtitle_card_order}`}>
                                    {item.population ? item.population : '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div key={index} className={styles.app_container_card_ord}>
                              <div className={`${styles.app_container_info_card} w-100`}>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Tipo
                                  </span>
                                  <span className={`col-6 ${styles.app_subtitle_card_order}`}>
                                    {item.companyTypeName ? item.companyTypeName : '-'}
                                  </span>
                                </div>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Empresa
                                  </span>
                                  <span className={`col-6 ${styles.app_subtitle_card_order}`}>
                                    {item.corporateClient ? item.corporateClient : '-'}
                                  </span>
                                </div>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Contrato
                                  </span>
                                  <span className={`col-12 ${styles.app_subtitle_card_order}`}>
                                    {item.contractName ? item.contractName : '-'}
                                  </span>
                                </div>
                                <div className='d-flex' style={{ gap: '1rem' }}>
                                  <span className={`col-6 ${styles.app_title_card_programming}`}>
                                    Población
                                  </span>
                                  <span className={`col-9 ${styles.app_subtitle_card_order}`}>
                                    {item.population ? item.population : '-'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Card>
                    );
                  } else return null;
                })}
                {detailPatient?.results?.contracts.map((item, index) => {
                  if (item.isMain === 1 && item.isEnabled === 1) {
                    return (
                      <Card
                        className={styles.app_card_custom_programming}
                        key={index}
                        onClick={() => {
                          postTicketFunction('insurance');
                          getInsurer(item);
                        }}
                      >
                        <div className='d-flex justify-content-between'>
                          <span className={styles.app_title_order}>Aseguradora Obligatoria</span>
                          {item.wsValidated === 1 && (
                            <OverlayTrigger
                              placement='top'
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipAlert()}
                            >
                              <img alt='alert' src={alert} />
                            </OverlayTrigger>
                          )}
                        </div>
                        <div key={index} className={styles.app_container_card_ord}>
                          <div className={styles.app_container_info_card}>
                            <div className='d-flex' style={{ gap: '1rem' }}>
                              <span className={`col-9 ${styles.app_title_card_programming}`}>
                                Empresa
                              </span>
                              <span className={`col-6 ${styles.app_subtitle_card_order}`}>
                                {item.corporateClient ? item.corporateClient : '-'}
                              </span>
                            </div>
                            <div className='d-flex' style={{ gap: '1rem' }}>
                              <span className={`col-9 ${styles.app_title_card_programming}`}>
                                Contrato
                              </span>
                              <span className={`col-9 ${styles.app_subtitle_card_order}`}>
                                {item.contractName ? item.contractName : '-'}
                              </span>
                            </div>
                            <div className='d-flex' style={{ gap: '1rem' }}>
                              <span className={`col-9 ${styles.app_title_card_programming}`}>
                                Población
                              </span>
                              <span className={`col-9 ${styles.app_subtitle_card_order}`}>
                                {item.population ? item.population : '-'}
                              </span>
                            </div>
                            <div className='d-flex' style={{ gap: '1rem' }}>
                              <span className={`col-9 ${styles.app_title_card_programming}`}>
                                Grupo de ingreso
                              </span>
                              <span className={`col-6 ${styles.app_subtitle_card_order}`}>
                                {item.crtName ? item.crtName : '-'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  } else return null;
                })}
              </>
            )}
          </OrdGenericTemplate>
        </div>
      </div>
    </>
  );
};

export default Programming;
