import { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { AddCircleOutline, ChevronLeft, ChevronRight } from '@material-ui/icons';
import AddVariable from './AddVariable';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import { customSwaltAlertAsistencial, loader } from '../../helpers';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { usePostMethod } from '../../Hooks';
import { Circle } from '@mui/icons-material';

export const Variables = ({ getInitialList, initialList = {} }) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;

  const [variableModalConfig, setVariableModalConfig] = useState({ isOpen: false, payload: null });
  const [page, setPage] = useState(1);
  const [trigger, setTrigger] = useState(0);

  const { trigger: updateVariableStatus, load: updateVariableLoader } = usePostMethod();

  useEffect(() => {
    const filters = {
      page: page,
      search: '',
      perpage: 10,
      idAccount: idEnterprise,
    };
    void getInitialList({
      url: '/medical/protocolConditions',
      objFilters: filters,
      token: token,
    });
  }, [idEnterprise, getInitialList, token, page, trigger]);

  const onChangeVariableStatus = variable => {
    updateVariableStatus({
      token: token,
      body: {
        enabled: variable?.enabled === 1 ? 0 : 1,
        idAccount: idEnterprise,
      },
      method: 'PATCH',
      url: `/medical/protocolConditions/${variable.id ?? ''}`,
      succesAction: results => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Variable actualizada exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        setTrigger(state => state + 1);
      },
    });
  };

  const totalPages = Math.ceil((initialList?.rowTotal ?? 1) / 10);

  return (
    <div>
      {updateVariableLoader && loader}
      <div className='w-100 d-flex justify-content-end align-items-center mt-3 mr-3'>
        <button
          className={`d-flex align-items-center ordGroupAddButton fw-bold text-muted ${tableStyles.hoverOrdAquaMarine}`}
          onClick={() => setVariableModalConfig({ isOpen: true })}
        >
          <span className='me-2'>Agregar variable</span>
          <AddCircleOutline />
        </button>
        <div className='d-flex align-items-center'>
          <ChevronLeft
            fontSize='large'
            className={`${tableStyles.ordClearBlueText} pointer`}
            onClick={() => setPage(page === 1 ? page : page - 1)}
          />
          <span className='text-muted fs-5 fw-bold'>
            {page} / {totalPages}
          </span>
          <ChevronRight
            fontSize='large'
            className={`${tableStyles.ordClearBlueText} pointer`}
            onClick={() => setPage(page === totalPages ? page : page + 1)}
          />
        </div>
      </div>
      {initialList?.results?.length > 0 &&
        initialList?.results?.map((e, index) => {
          return (
            <Fragment key={index}>
              <Row className='mx-3 pt-3'>
                <Col
                  xs={12}
                  className={`${tableStyles.ordSearchSections} my-2 ml-2`}
                  style={{ borderRadius: '10px' }}
                >
                  <Row className={`my-2`}>
                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId='lName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                          <b> &nbsp; Nombre</b>
                        </Form.Label>
                        <Form.Control
                          value={e.name}
                          disabled
                          className={`ord-roundInput`}
                          type='text'
                          placeholder=''
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId='lName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                          <b> &nbsp; Campo de historia clínica</b>
                        </Form.Label>
                        <Form.Control
                          value={e.fieldName}
                          disabled
                          className={`ord-roundInput`}
                          type='text'
                          placeholder=''
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId='lName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                          <b> &nbsp; Condición</b>
                        </Form.Label>
                        <Form.Control
                          value={e.value}
                          disabled
                          className={`ord-roundInput`}
                          type='text'
                          placeholder=''
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={2} className='d-flex align-items-center justify-content-end'>
                      <Circle htmlColor={e?.enabled ? '#83c036' : '#f39682'} fontSize='small' />
                      <span className='ms-2 text-muted w-50 text-start'>
                        {e?.enabled ? 'Habilitado' : 'Deshabilitado'}
                      </span>
                    </Col>

                    <Col xs={1} className='d-flex align-items-center justify-content-end'>
                      <CustomPopupExtend
                        showEdit={true}
                        showEnable={true}
                        position='bottom right'
                        triggerSrc={ThreeDots}
                        editClickEvent={() => setVariableModalConfig({ isOpen: true, payload: e })}
                        isEnabled={e?.enabled === 1}
                        enableClickEvent={() => {
                          customSwaltAlertAsistencial({
                            icon: 'warning',
                            titleColor: '#1ABCD2',
                            title: '¿Está seguro?',
                            text: `La variable será ${e?.enabled ? 'deshabilitada' : 'habilitada'}`,
                            confirmButtonText: 'Si, continuar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: true,
                          }).then(response => {
                            if (response.isConfirmed) {
                              onChangeVariableStatus(e);
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          );
        })}

      <AddVariable
        open={variableModalConfig.isOpen}
        editPayload={variableModalConfig.payload}
        onClose={() => setVariableModalConfig({ isOpen: false })}
        onSuccess={() => {
          setTrigger(trigger + 1);
        }}
      />
    </div>
  );
};
